<template>
  <div class="apartment-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
      title: "",
      emptyText: "请等待...",
      columns: [],
      list: [],
      buttons: [],
      searchInps: [],
      searchForm: {},
      multipleSelection: [],
      currentPage: 1,
      skip: 1,
      limit: 10,
      total: 0,
      isShowHeaderRefresh: false,
      isShowBack: false,
      isLoading: true,
      isNotShowSelection: false, //是否显示表格列表多选框
      opt: {
        title: "转账记录",
        search: [
          {
            key: "date",
            label: "起止日期",
            type: "daterange",
            timeType: "yyyy-MM-dd"
          },
          {
            key: "status",
            label: "转账情况",
            type: "select-no-filterable",
            opt: {
              list: [
                {
                  label: "已转账",
                  value: '1'
                },
                {
                  label: "未转账",
                  value: '2'
                }
              ]
            }
          }
        ],
        columns: [
          {
            label: "提款流水号",
            key: "withdrawId"
          },
          { label: "提款人", key: "withdrawAdult" },
          { label: "提款时间", key: "withdrawTime" },
          { label: "提款金额（元）", key: "withdrawAmount" },
          {
            label: "转账情况",
            key: "transferState"
          },
          {
            label: "操作",
            key: "action",
            type: "action",
            opt: {
              list: [
                {
                  label: "明细",
                  on(row) {
                    _this.cache.set("statementDetailTitle", row.reconDate);
                    _this.$router.push({
                      path: "/main/money/detail/show/" + row.withdrawId,
                      query: {
                        withdrawId: row.withdrawId,
                        transferPath: row.transferPath
                      }
                    });
                  }
                },
                {
                  label: "转账",
                  on(row) {
                    _this.cache.set("statementDetailTitle", row.reconDate);
                    _this.$router.push({
                      path: "/main/money/zhuan/edit/" + row.withdrawId,
                      query: {
                        withdrawId: row.withdrawId,
                        transferPath: row.transferPath,
                        reconDate: row.reconDate,
                        reconState: row.reconState,
                        transferState: row.transferState,
                        withdrawAdult: row.withdrawAdult,
                        withdrawPhone: row.withdrawPhone,
                        withdrawAmount: row.withdrawAmount,
                        withdrawTime: row.withdrawTime
                      }
                    });
                  }
                }
              ]
            }
          }
        ]
      }
    };
  },
  created() {
    console.log("apartment-list created!!");
  },
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        startTime: opt.searchForm["date"] ? opt.searchForm["date"][0] : null,
        endTime: opt.searchForm["date"] ? opt.searchForm["date"][1] : null,
        transferState: opt.searchForm["status"]
      };
      this.post("/finance-service/withdraw/transferRecord", dto, {
        isUseResponse: true
      }).then(res => {
        console.log(res);
        for (let i = 0; i < res.data.data.length; i++) {
          let item = res.data.data[i];
          // item.reconDate = this.format(item.date, 1);
          item.reconState = ["正常", "异常", "已冲正"][item.reconState - 1];
          if (item.reconState == 2) {
            item.bk = 3;
          }
          // 隐藏操作按钮显示
          if (item.transferState == '1') {
            item.action = [true,false];
          } else {
            item.action = [false,true];
          }
          item.transferState =  item.transferState == '1' ? "已转账" : "未转账";
        }
        opt.cb(res.data); 
      });
    }
  }
};
</script>